body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  height: 100vh;
  text-align: center;
  font-family: 'Architects Daughter', cursive;
  font-size: 4rem;
  font-weight: bolder;
}

.slider {
  height: 200px
}
